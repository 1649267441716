var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 elevation-2",staticStyle:{"background-color":"#f0f0f0"}},[(_vm.type === 'category')?_c('v-layout',[_c('v-row',{staticClass:"mb-4",attrs:{"no-gutters":""}},[_c('div',{staticClass:"text-center link-handle align-self-center px-6"},[_c('v-icon',[_vm._v("drag_handle")])],1),_c('v-btn',{staticClass:"align-self-center",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1):_vm._e(),(_vm.type === 'category')?_c('Fields',{attrs:{"schema":{
    title: {
      type: 'string',
      label: 'Nombre',
      description: 'Debe ser único y reconocible.',
      newDesign: true
    },
    icon: {
      type: 'string',
      label: 'Ícono',
      description:
        'Visitar https://material.io/icons para una lista de íconos.',
      fieldOptions: {
        icon: _vm.normalizedIcon
      },
      optional: true,
      newDesign: true
    }
  },"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables,"validationErrors":_vm.validationErrors},model:{value:(_vm.syncLink),callback:function ($$v) {_vm.syncLink=$$v},expression:"syncLink"}}):_vm._e(),_c('Fields',{attrs:{"id":"link-field-edit-card-2","schema":{
    path: {
      type: 'string',
      label: 'Ruta',
      description:
        'Debe empezar con /. Puedes añadir comodines de parámetro usando la sintáxis :parametro.',
      sizeSmall: 12,
      sizeLarge: 6,
      fieldOptions: {
        validatePattern: '^\/(?:[a-z\d:%\s_0-9-]+(?:\/[a-z\d:%\s_0-9-]+)*)?$',
        validateCaseInsensitive: true,
        validateCustomError:
          'Debe empezar con / y no puede contener espacios.'
      },
      newDesign: true
    },
    roles: {
      type: 'roleSelect',
      __graphQLType: '[ID]',
      label: 'Roles',
      sizeSmall: 12,
      sizeLarge: 6,
      fieldOptions: {
        multi: true
      },
      optional: true,
      newDesign: true
    }
  },"disabled":_vm.disabled,"environmentVariables":_vm.environmentVariables,"validationErrors":_vm.validationErrors},model:{value:(_vm.syncLink),callback:function ($$v) {_vm.syncLink=$$v},expression:"syncLink"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }